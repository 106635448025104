(function () {
    'use strict';

    angular.module('informaApp')
        .directive('infFileInput', FileInput);

    function FileInput() {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-file-input/template.ptl.html',

            scope: {
                options: '=',
                file: '=?',
                error: '=?'
            },

            link: function (scope, element, attrs) {

            }
        };
    }
})();

